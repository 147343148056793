<template>
  <!-- 喷粉登记1 -->
  <div>
    <div class="penfen1CheckIn_main">
      <van-nav-bar :title="pageTitle"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true" />
      <div class="content"
           :style="viewType != '3' && viewType != '5'?'height: calc(100% - 2.293334rem);':'height: calc(100% - 48px);'">
        <div class="form_and_table">
          <van-form id="main_form">
            <van-field class="not_canedi"
                       :label="'工号'"
                       v-model="form.empcode"
                       readonly />
            <van-field class="not_canedi"
                       :label="'姓名'"
                       v-model="form.empname"
                       readonly />
            <van-field class="not_canedi"
                       :label="'厂别'"
                       v-model="form.deptname1"
                       readonly />
            <div v-if="canEdit">
              <van-field clickable
                         readonly
                         :clearable="true"
                         clear-trigger="always"
                         :required="true"
                         right-icon="edit"
                         name="datePicker"
                         :label="'生产日期'"
                         :value="formatDate(form.yymmdd, 'yyyy-MM-dd')"
                         :placeholder="$t('module.SelectDate')"
                         @clear="clearDate( )"
                         @click="getDatePicker('yymmdd')" />
              <van-field readonly
                         clickable
                         right-icon="edit"
                         name="picker"
                         :required="true"
                         :value="form.shifttype"
                         :label="'白/夜班'"
                         :placeholder="$t('module.PleaseChoose')"
                         @click="showShiftPicker = true" />
              <van-field readonly
                         clickable
                         right-icon="edit"
                         name="picker"
                         :required="true"
                         :value="form.go1"
                         :label="'上班时间'"
                         :placeholder="$t('module.PleaseChoose')"
                         @click="getTimes('go1')" />
              <van-field readonly
                         clickable
                         right-icon="edit"
                         name="picker"
                         :required="true"
                         :value="form.off1"
                         :label="'下班时间'"
                         :placeholder="$t('module.PleaseChoose')"
                         @click="getTimes('off1')" />
              <van-field :label="'生产工时'"
                         right-icon="edit"
                         :required="true"
                         type="number"
                         v-model="form.sctimes" />
              <van-field :label="'考勤工时'"
                         right-icon="edit"
                         :required="true"
                         type="number"
                         v-model="form.kqtimes" />
              <van-field :label="'异常工时'"
                         right-icon="edit"
                         :required="false"
                         type="number"
                         v-model="form.yctimes" />
              <van-field :label="'实际上班人数'"
                         right-icon="edit"
                         :required="true"
                         type="digit"
                         v-model="form.sjcount" />
              <van-field :label="'应上班人数'"
                         right-icon="edit"
                         :required="true"
                         type="digit"
                         v-model="form.ysbcount" />
              <van-field :label="'更换滤芯/次'"
                         class="last_cell"
                         right-icon="edit"
                         :required="true"
                         type="digit"
                         v-model="form.ghlx" />
            </div>
            <div v-else>
              <van-field class="not_canedi"
                         :label="'生产日期'"
                         v-model="form.yymmdd"
                         readonly />
              <van-field class="not_canedi"
                         :label="'白/夜班'"
                         v-model="form.shifttype"
                         readonly />
              <van-field class="not_canedi"
                         :label="'上班时间'"
                         v-model="form.go1"
                         readonly />
              <van-field class="not_canedi"
                         :label="'下班时间'"
                         v-model="form.off1"
                         readonly />
              <van-field class="not_canedi"
                         :label="'生产工时'"
                         v-model="form.sctimes"
                         readonly />
              <van-field class="not_canedi"
                         :label="'考勤工时'"
                         v-model="form.kqtimes"
                         readonly />
              <van-field class="not_canedi"
                         :label="'异常工时'"
                         v-model="form.yctimes"
                         readonly />
              <van-field class="not_canedi"
                         :label="'实际上班人数'"
                         v-model="form.sjcount"
                         readonly />
              <van-field class="not_canedi"
                         :label="'应上班人数'"
                         v-model="form.ysbcount"
                         readonly />
              <van-field class="not_canedi last_cell"
                         :label="'更换滤芯/次'"
                         v-model="form.ghlx"
                         readonly />
            </div>
            <van-field class="not_canedi"
                       :label="'编号'"
                       v-model="form.listno"
                       readonly />
            <van-field class="not_canedi"
                       :label="'制表人'"
                       v-model="form.createdPeo"
                       readonly />
            <van-field class="not_canedi"
                       :label="'制表日期'"
                       v-model="form.createDate"
                       readonly />
          </van-form>
          <div class="isSon_body"
               v-if="autoid">
            <!-- 子表页签Tab -->
            <van-tabs v-model="activeSon"
                      @change="changTab">
              <van-tab title="生产明细"
                       name="1"> </van-tab>
              <van-tab title="人员明细"
                       name="2"> </van-tab>
            </van-tabs>
            <div class="sonTable_btn"
                 v-if="showSonBtn && canEdit">
              <van-button v-if="activeSon=='2'"
                          type="info"
                          size="small"
                          style="width:80px"
                          @click="getPf1Emps">获取名单</van-button>
              <van-button type="primary"
                          size="small"
                          @click="goToAdd">新增</van-button>
              <van-button type="default"
                          size="small"
                          @click="goToEdit">修改</van-button>
              <van-button type="warning"
                          size="small"
                          @click="goToDelete">删除</van-button>
            </div>
            <div class="sontable_box">
              <table ref="table"
                     class="table">
                <!-- 表头 -->
                <tr class="top">
                  <th v-for="(item_th, index_th) in auditThList"
                      :key="index_th">
                    <div class="title"
                         :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                      {{ item_th.chnname }}
                    </div>
                  </th>
                </tr>
                <!--展示列表数据 -->
                <tr class="cont"
                    v-for="(item_tr, index_tr) in auditTableData"
                    :key="index_tr"
                    @click.stop="changeAuditDataByTd(item_tr)">
                  <td v-for="(item_td, index_td) in auditThList"
                      :key="index_td">
                    <div class="comm_box">
                      <div class="boolean_box"
                           v-if="item_td.datatype == 'checkbox'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                      </div>
                      <div class="boolean_box"
                           v-else-if="item_td.datatype == 'checkbox_disable'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"
                                      shape="square"
                                      disabled></van-checkbox>
                      </div>
                      <div class="input_box"
                           v-else-if="item_td.datatype == 'num'">
                        <van-field v-model="item_tr[item_td.fieldname]"
                                   :placeholder="item_td.chnname" />
                      </div>
                      <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                    </div>
                  </td>
                </tr>
                <tr class="nodata"
                    v-if="auditTableData.length == 0">
                  <td align="center"
                      :colspan="auditThList.length">暂无数据</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="footerBox"
           v-if="viewType != '3' && viewType != '5'">
        <div class="flexBox"
             v-if="canEdit">
          <van-button v-if="viewType != '2'"
                      @click="staging"
                      type="primary"
                      style="width: 45%;height: 100%;">暂存</van-button>
          <van-button @click="submit"
                      type="info"
                      style="width: 45%;height: 100%;">提交</van-button>
        </div>
        <div class="flexBox"
             v-else>
          <van-button @click="agree"
                      type="primary"
                      style="width: 45%;height: 100%;">同意</van-button>
          <van-button @click="disagree"
                      type="info"
                      style="width: 45%;height: 100%;">不同意</van-button>
        </div>
      </div>
    </div>
    <!-- 日期日历弹窗 -->
    <!-- <van-calendar v-model="showCalendar"
                  :type="'single'"
                  :allow-same-day="false"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmCal" /> -->
    <!-- 选择日期 -->
    <van-popup v-model="showCalendar"
               round
               position="bottom">
      <van-datetime-picker v-model="curDeftDate"
                           type="date"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :formatter="formatter"
                           @confirm="onConfirmCal"
                           @cancel="showCalendar = false" />
    </van-popup>
    <!-- 选择时间 -->
    <van-popup v-model="showDate"
               position="bottom"
               round>
      <van-datetime-picker v-model="currentDate"
                           type="time"
                           title="选择时间"
                           :min-date="minDate"
                           :max-date="maxDate"
                           @confirm="selTime"
                           @cancel="showDate = false" />
    </van-popup>
    <!-- 班次类别 -->
    <van-popup v-model="showShiftPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="['白班','夜班']"
                  @confirm="confirmShift"
                  @cancel="showShiftPicker = false" />
    </van-popup>
    <!-- 选择部门树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="TreeName"
             module="817"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
    <!-- 生产明细编辑弹窗 -->
    <van-popup v-model="showEditSC"
               round
               :style="{ height: '60%' , width: '90%'}"
               class="editSC_main_pop">
      <van-nav-bar title="生产明细"
                   :fixed="true">
        <template #right>
          <van-icon name="cross"
                    size="18"
                    color="#ffffff"
                    @click="showEditSC = false" />
        </template>
      </van-nav-bar>
      <div class="content">
        <van-form>
          <van-field v-if="editType == 'add'"
                     required
                     readonly
                     clickable
                     label="喷粉1类别"
                     right-icon="edit"
                     v-model="scForm.typename"
                     :placeholder="$t('module.PleaseChoose')"
                     @click="getPickerData" />
          <van-field v-else
                     class="not_canedi"
                     :label="'喷粉1类别'"
                     v-model="scForm.typename    "
                     readonly />
          <van-field :label="'详细情况'"
                     v-model="scForm.remark"
                     :required="true"
                     right-icon="edit"
                     rows="5"
                     autosize
                     type="textarea"
                     :placeholder="$t('module.PleaseEnter')" />
        </van-form>
        <div class="footerBox">
          <div class="flexBox">
            <van-button @click="confirmSC"
                        type="info"
                        style="width: 80%;height: 100%;">确定</van-button>

          </div>
        </div>
      </div>
    </van-popup>
    <!-- 喷粉类别 -->
    <van-popup v-model="showSpurtPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="spurtList"
                  @confirm="confirmSpurt"
                  @cancel="showSpurtPicker = false" />
    </van-popup>
    <!-- 人员明细编辑弹窗 -->
    <van-popup v-model="showEditRY"
               round
               :style="{ height: '80%' , width: '90%'}"
               class="editSC_main_pop">
      <van-nav-bar title="人员明细"
                   :fixed="true">
        <template #right>
          <van-icon name="cross"
                    size="18"
                    color="#ffffff"
                    @click="showEditRY = false" />
        </template>
      </van-nav-bar>
      <div class="content">
        <van-form>
          <van-field readonly
                     clickable
                     right-icon="edit"
                     name="picker"
                     :required="true"
                     :value="ryForm.empcode"
                     :label="'工号'"
                     :placeholder="$t('module.PleaseChoose')"
                     @click="getDatePicker('ryemp')" />
          <van-field class="not_canedi"
                     :label="'姓名'"
                     v-model="ryForm.empname    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'职务'"
                     v-model="ryForm.staname    "
                     readonly />
          <van-field class="not_canedi"
                     :label="'所属班组'"
                     v-model="ryForm.deptname3    "
                     readonly />
          <van-field :required="true"
                     :label="'加班工时/h'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.jbtimes"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'请假工时/h'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.qjtimes"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'调动协助工时/h'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.ddtxtimes"
                     :placeholder="$t('module.PleaseEnter')" />
          <van-field :required="true"
                     :label="'非生产工时/h'"
                     type="number"
                     right-icon="edit"
                     v-model="ryForm.fsctimes"
                     :placeholder="$t('module.PleaseEnter')" />
        </van-form>
        <div class="footerBox">
          <div class="flexBox">
            <van-button @click="confirmRY"
                        type="info"
                        style="width: 80%;height: 100%;">确定</van-button>

          </div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
import { Toast, Dialog } from 'vant'
import {
  getPf1EmpList,
  savePf1InputData,
  getPf1InputData,
  addPf1ScmxRecord,
  savePf1ScmxRecord,
  deletePf1ScmxRecord,
  addPf1EmpRecord,
  savePf1EmpRecord,
  deletePf1EmpRecord,
  postPf1InputData,
  getCurUserInfo,
  agreeTmList,
  notAgreeTmlist
} from '@api/wxjj.js'
import { GetFilterDropDownList } from '@api/wx.js'
import PopTree from "@components/PopTree/index.vue";
export default {
  components: {
    PopTree
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      viewType: 0, // 1待审批 2待提交 3已审批 4不同意
      canEdit: true,
      moduleno: 819,
      autoid: 0,
      TreeName: '',
      whichTree: "",
      form: {
        autoid: null,
        empcode: '',
        empname: '',
        deptname1: '',
        empid: '', // --登记人工号id
        yymmdd: '', // --生产日期
        shifttype: '', // --班次类别，只能选：白班、夜班
        go1: '', // --上班时间，格式 hh:mm
        off1: '', // --下班时间，格式 hh:mm
        sctimes: '', // --生产工时
        kqtimes: '', // --考勤工时
        ysbcount: '', // --应上班人数
        sjcount: '', // --实际上班人数
        yctimes: '', // --异常工时
        ghlx: '', // --更换滤芯次数
        remark: '', // --备注
        username: '', // 
        createdPeo: '',
        createDate: this.formatDate(new Date(), 'yyyy-MM-dd')
      },
      curDeftDate: new Date(),
      showCalendar: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      showDate: false, // 时间弹窗
      currentDate: this.formatDate(new Date(), 'HH:mm'),
      whichTime: "go1",
      showShiftPicker: false, // 班次类型弹窗
      activeSon: '1',
      auditThList: [], // 表头
      auditTableData: [], // 表格数据
      initAuditTableData: [], // // 表格数据初始数据
      showSonBtn: true,
      scmx_data: [], // 生产明细 
      rymx_data: [], // 人员明细 
      /*** 生产明细子表弹窗参数-----------start */
      showEditSC: false, // 生产明细弹窗
      editType: 'add',
      scForm: {
        mautoid: null,
        typename: '',  // --喷粉类别 
        remark: '', // --未完成原因
        username: ''
      },
      showSpurtPicker: false,
      spurtList: [],
      /*** 生产明细子表弹窗参数-----------end */
      /*** 人员明细子表弹窗参数-----------start */
      showEditRY: false, // 人员明细弹窗 
      ryForm: {
        mautoid: null,
        empid: '', // --工号id
        empcode: '',
        empname: '',
        staname: '',
        jbtimes: '', // --加班工时
        qjtimes: '', // --请假工时
        ddtxtimes: '', // --调动协助工时
        fsctimes: '' // --非生产工时  
      },
      /*** 人员明细子表弹窗参数-----------end */

    }
  },
  computed: {
    pageTitle () {
      let title = '喷粉1登记'
      if (this.$route.query)
      {
        title = this.$route.query.title
      }
      return title
    }
  },
  mounted () {
    // // 1待审批 2待提交 3已审批 4不同意
    if (this.$route.query.type)
    {
      this.viewType = this.$route.query.type
      if (this.viewType == '1' || this.viewType == '3' || this.viewType == '5')
      {
        this.canEdit = false;
      }
    }
    if (this.$route.query.autoid)
    {
      this.autoid = this.$route.query.autoid
    }
    if (this.autoid == 0)
    {
      this.getCurUserInfoData()
    }
    this.auditThList = [
      {
        fieldname: "checked",
        chnname: "",
        gridwith: 10,
        datatype: 'checkbox'
      },
      {
        fieldname: "typename",
        chnname: "喷粉类别",
        gridwith: 60,
        datatype: 'str'
      },
      {
        fieldname: "remark",
        chnname: "详细情况",
        gridwith: 100,
        datatype: 'str'
      }
    ]
    this.getZzInputDataInfo()
  },
  methods: {
    formatter (type, val) {
      if (type === "year")
      {
        return val + "年";
      }
      if (type === "month")
      {
        return val + "月";
      }
      if (type === "day")
      {
        return val + "日";
      }
      return val;
    },
    // 人员明细子表获取名单按钮
    getPf1Emps () {
      getPf1EmpList({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('获取成功！')
          this.getZzInputDataInfo()
        }
      })
    },
    // 喷粉1类别选择回调
    confirmSpurt (val) {
      this.scForm.typename = val
      this.showSpurtPicker = false
    },
    // 获取下拉选内容
    getPickerData () {
      this.spurtList = []
      GetFilterDropDownList({
        moduleno: this.moduleno,
        username: this.userInfo.username,
        listsql: '@喷粉1类别'
      }).then(res => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.spurtList.push(res.data[i].dicvalue)
          }
        }
      })
      this.showSpurtPicker = true
    },
    // 班次选择回调
    confirmShift (val) {
      console.log(val, 'valvalvalval');
      this.form.shifttype = val
      this.showShiftPicker = false
    },
    // 选择时间输入
    getTimes (val) {
      this.whichTime = val
      this.showDate = true;
    },
    // 选择时间弹窗回调
    selTime (val) {
      if (this.whichTime == 'go1')
      {
        this.form.go1 = val
      } else
      {
        this.form.off1 = val
      }
      this.showDate = false;
    },
    // 同意单据
    agree () {
      agreeTmList({
        moduleno: this.moduleno,
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('审批成功！');
          this.onClickLeft()
        }
      })
    },
    // 不同意单据
    disagree () {
      notAgreeTmlist({
        moduleno: this.moduleno,
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        } else
        {
          Toast.success('审批成功！');
          this.onClickLeft()
        }
      })
    },
    // 人员明细弹窗确定按钮
    confirmRY () {
      if (!this.ryForm.empcode)
      {
        Toast.fail('工号不能为空！')
        return
      }
      if (!this.ryForm.jbtimes)
      {
        Toast.fail('加班工时不能为空！')
        return
      }
      if (!this.ryForm.qjtimes)
      {
        Toast.fail('请假工时不能为空！')
        return
      }
      if (!this.ryForm.ddtxtimes)
      {
        Toast.fail('调动协助工时不能为空！')
        return
      }
      if (!this.ryForm.fsctimes)
      {
        Toast.fail('非生产工时不能为空！')
        return
      }
      this.ryForm.username = this.userInfo.username
      if (this.editType == 'add')
      {
        // 新增
        this.ryForm.mautoid = this.autoid
        addPf1EmpRecord(this.ryForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('新增成功！')
            this.showEditRY = false
            this.getZzInputDataInfo()
          }
        })
      } else
      {
        // 修改 
        savePf1EmpRecord(this.ryForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('修改成功！')
            this.showEditRY = false
            this.getZzInputDataInfo()
          }
        })
      }
    },
    // 生产明细弹窗确定按钮
    confirmSC () {
      if (!this.scForm.typename)
      {
        Toast.fail('喷粉类别不能为空！')
        return
      }
      if (!this.scForm.remark)
      {
        Toast.fail('详细情况不能为空！')
        return
      }
      this.scForm.username = this.userInfo.username
      if (this.editType == 'add')
      {
        // 新增
        this.scForm.mautoid = this.autoid
        addPf1ScmxRecord(this.scForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('新增成功！')
            this.showEditSC = false
            this.getZzInputDataInfo()
          }
        })
      } else
      {
        // 修改 
        savePf1ScmxRecord(this.scForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Toast.success('修改成功！')
            this.showEditSC = false
            this.getZzInputDataInfo()
          }
        })
      }
    },
    // 子表新增按钮
    goToAdd () {
      if (this.activeSon == '1')
      {
        // 去生产明细
        this.editType = 'add'
        this.scForm = {
          mautoid: null,
          typename: '',  // --喷粉类别 
          remark: '', // --未完成原因
          username: ''
        }
        this.showEditSC = true
      } else
      {
        // 去人员明细 
        this.editType = 'add'
        this.ryForm = {
          mautoid: null,
          empid: '', // --工号id
          empcode: '',
          empname: '',
          staname: '',
          jbtimes: '', // --加班工时
          qjtimes: '', // --请假工时
          ddtxtimes: '', // --调动协助工时
          fsctimes: '' // --非生产工时  
        }
        this.showEditRY = true
      }
    },
    // 子表修改
    goToEdit () {
      const checkedData = this.auditTableData.filter(it => { return it.checked })
      if (checkedData.length != 1)
      {
        Toast.fail('请选择1条记录！');
        return
      }
      if (this.activeSon == '1')
      {
        // 去生产明细
        this.editType = 'edit'
        this.scForm = { ...this.scForm, ...checkedData[0] }
        this.showEditSC = true
      } else
      {
        // 去人员明细 
        this.editType = 'edit'
        this.ryForm = { ...this.ryForm, ...checkedData[0] }
        this.showEditRY = true
      }
    },
    // 子表删除
    goToDelete () {
      const checkedData = this.auditTableData.filter(it => { return it.checked })
      if (checkedData.length == 0)
      {
        Toast.fail('请选择记录！');
        return
      }
      const ids = checkedData.map(it => { return it.autoid }) || []
      let params = {
        autoidlist: ids.join(','),
        username: this.userInfo.username
      }
      if (this.activeSon == '1')
      {
        // 生产明细删除 
        Dialog.confirm({
          // title: '标题',
          message: this.$t('module.sureToDelete')
        }).then(() => {
          deletePf1ScmxRecord(params).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Toast.success(this.$t('module.DelSuc'))
              this.getZzInputDataInfo()
            }
          })
        }).catch(() => {
          // on cancel
        });
      } else
      {
        // 人员明细删除 
        Dialog.confirm({
          // title: '标题',
          message: this.$t('module.sureToDelete')
        }).then(() => {
          deletePf1EmpRecord(params).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              Toast.success(this.$t('module.DelSuc'))
              this.getZzInputDataInfo()
            }
          })
        }).catch(() => {
          // on cancel
        });
      }
    },
    // 页签切换事件
    changTab (name, title) {
      if (name == '1')
      {
        // 生产明细
        this.showSonBtn = true
        this.auditThList = [
          {
            fieldname: "checked",
            chnname: "",
            gridwith: 10,
            datatype: 'checkbox'
          },
          {
            fieldname: "typename",
            chnname: "喷粉类别",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "remark",
            chnname: "详细情况",
            gridwith: 100,
            datatype: 'str'
          }
        ]
        this.auditTableData = this.scmx_data
      } else
      {
        // 人员明细
        this.showSonBtn = true
        this.auditThList = [
          {
            fieldname: "checked",
            chnname: "",
            gridwith: 10,
            datatype: 'checkbox'
          },
          {
            fieldname: "empcode",
            chnname: "工号",
            gridwith: 50,
            datatype: 'str'
          },
          {
            fieldname: "empname",
            chnname: "姓名",
            gridwith: 50,
            datatype: 'str'
          },
          {
            fieldname: "staname",
            chnname: "职务",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "jbtimes",
            chnname: "加班工时/h",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "qjtimes",
            chnname: "请假工时/h",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "ddtxtimes",
            chnname: "调动协助/h",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "fsctimes",
            chnname: "非生产工时/h",
            gridwith: 60,
            datatype: 'str'
          },
          {
            fieldname: "deptname3",
            chnname: "所属班/组/线",
            gridwith: 60,
            datatype: 'str'
          }
        ]
        this.auditTableData = this.rymx_data
      }
    },
    // 点击表格行
    changeAuditDataByTd (val) {
      this.auditTableData.forEach(ele => {
        if (val.autoid == ele.autoid)
        {
          ele.checked = !ele.checked
        }
      });
      this.$forceUpdate()
    },
    // 校验主表form
    validateForm () {
      if (!this.form.yymmdd)
      {
        Toast.fail('生产日期不能为空！')
        return false
      }
      if (!this.form.shifttype)
      {
        Toast.fail('班次类别不能为空！')
        return false
      }
      if (!this.form.go1)
      {
        Toast.fail('上班时间不能为空！')
        return false
      }
      if (!this.form.off1)
      {
        Toast.fail('下班时间不能为空！')
        return false
      }
      if (!this.form.sctimes)
      {
        Toast.fail('生产工时不能为空！')
        return false
      }
      if (!this.form.kqtimes)
      {
        Toast.fail('考勤工时不能为空！')
        return false
      }
      // if (!this.form.yctimes)
      // {
      //   Toast.fail('异常工时不能为空！')
      //   return false
      // }
      if (!this.form.sjcount)
      {
        Toast.fail('实际上班人数不能为空！')
        return false
      }
      if (!this.form.ysbcount)
      {
        Toast.fail('应上班人数不能为空！')
        return false
      }
      if (!this.form.ghlx)
      {
        Toast.fail('更换滤芯次数不能为空！')
        return false
      }
      return true
    },
    // 暂存
    staging () {
      this.form.empid = this.userInfo.empid
      this.form.username = this.userInfo.username
      return new Promise((resolve, reject) => {
        const results = this.validateForm()
        if (!results)
        {
          reject(false)
        } else
        {
          savePf1InputData(this.form).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info)
            } else
            {
              this.form.autoid = res.data[0].autoid
              this.autoid = res.data[0].autoid
              Toast.success('暂存成功！')
              this.getZzInputDataInfo()
              resolve(this.autoid)
            }
          })
        }
      }).catch(e => { })
    },
    // 总装主表及3个子表数据
    getZzInputDataInfo () {
      getPf1InputData({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        const info = res.baseinfo[0]
        if (info)
        {
          this.form = { ...this.form, ...info }
          this.form.createdPeo = info.empname
        }
        this.scmx_data = res.scmx_data
        this.rymx_data = res.rymx_data
        if (this.activeSon == '1')
        {
          this.scmx_data.forEach(ele => {
            ele.checked = false
          });
          this.auditTableData = this.scmx_data
        } else
        {
          this.rymx_data.forEach(ele => {
            ele.checked = false
          });
          this.auditTableData = this.rymx_data
        }
      })
    },
    //  提交
    async submit () {
      if (this.autoid)
      {
        postPf1InputData({
          autoid: this.autoid,
          username: this.userInfo.username
        }).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
          } else
          {
            Dialog.alert({
              message: '提交成功！',
            }).then(() => {
              this.onClickLeft()
            });
          }
        })
      } else
      {
        this.staging().then(res => {
          if (res)
          {
            postPf1InputData({
              autoid: this.autoid,
              username: this.userInfo.username
            }).then(res => {
              if (res.data[0].info)
              {
                Toast.fail(res.data[0].info)
              } else
              {
                Dialog.alert({
                  message: '提交成功！',
                }).then(() => {
                  this.onClickLeft()
                });
              }
            })
          }
        }).catch(error => {

        })
      }
    },
    // 获取默认值
    getCurUserInfoData () {
      getCurUserInfo({
        moduleno: this.moduleno,
        username: this.userInfo.username
      }).then(res => {
        const result = res.data[0]
        this.form.empcode = result.empcode
        this.form.empname = result.empname
        this.form.deptname1 = result.deptname1
        this.form.deptname2 = result.deptname2
        this.form.listno = result.listno
        this.form.createdPeo = result.empname
      })
    },
    // 回退
    onClickLeft () {
      if (this.$route.query.autoid)
      {
        this.$router.push('/toDoList')
      } else
      {
        this.$router.push('/home')
      }
    },
    // 树选择回调
    treeSelectValEvent (data) {
      if (this.TreeName == 'deptnumtree')
      {
        this.form.min_deptname = data.deptname;
      } else if (this.TreeName == 'employee')
      {
        console.log(data, 'data');
        if (this.whichTree == 'emp')
        {
          this.scForm.zrr_empid = data.empid
          this.scForm.zrr_empname = data.empname
        } else
        {
          this.ryForm.empid = data.empid
          this.ryForm.empcode = data.empcode
          this.ryForm.empname = data.empname
        }
      }
    },
    // 点击日期选择事件
    getDatePicker (val) {
      this.whichTree = val
      if (val == 'dept')
      {
        this.TreeName = 'deptnumtree'
        this.$refs.popTreeNode.module = "817";
        this.$refs.popTreeNode.treename = "deptnumtree";
        this.$refs.popTreeNode.editstate = 2;
        this.$refs.popTreeNode.showEmpTreePop('选择部门');
      } else if (val == 'emp' || val == "ryemp")
      {
        this.TreeName = 'employee'
        this.$refs.popTreeNode.module = "817";
        this.$refs.popTreeNode.treename = "emptree";
        this.$refs.popTreeNode.editstate = 2;
        this.$refs.popTreeNode.showEmpTreePop(val == 'emp' ? '选择责任人' : "选择人员");
      } else
      {
        this.showCalendar = true;
      }
    },
    // 日期选择回调
    onConfirmCal (date) {
      const val = this.formatDate(date, 'yyyy-MM-dd')
      this.form.yymmdd = val
      this.showCalendar = false
    },
    // 清除日期或时间
    clearDate () {
      this.form.yymmdd = ''
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else if (rule == 'yyyy-MM-dd HH:mm')
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
        } else if (rule == 'HH:mm')
        {
          return (
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm) +
            ':' +
            this.add0(s)
          )
        }
      } else
      {
        return ''
      }
    }
  }

}
</script>

<style scoped lang="less">
.editSC_main_pop {
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 96px);
    margin-top: 96px;
    /deep/.van-form {
      height: calc(100% - 80px);
      overflow-y: auto;
      .van-field {
        .van-field__label {
          width: 8em;
        }
      }
      .not_canedi {
        .van-field__value {
          .van-field__body {
            .van-field__control {
              color: #999999;
            }
          }
        }
      }
    }
    .footerBox {
      height: 80px;
      background: #fff;
      display: flex;
      align-items: center;
      .flexBox {
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
.penfen1CheckIn_main {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // height: calc(100% - 216px);
    margin-top: 96px;
    overflow-y: auto;
    // display: flex;
    // flex-direction: column;
    .form_and_table {
      // height: calc(100% - 120px);
      // flex: 1;
      // display: flex;
      // flex-direction: column;
      /deep/.van-form {
        margin-bottom: 16px;
        .van-field {
          .van-field__label {
            width: 8em;
          }
        }
        .not_canedi {
          .van-field__value {
            .van-field__body {
              .van-field__control {
                color: #999999;
              }
            }
          }
        }
        .last_cell::after {
          display: block !important;
          border-bottom: 2px solid #ebedf0;
        }
      }
      .isSon_body {
        // flex: 1;
        // display: flex;
        // flex-direction: column;
        .sonTable_btn {
          display: flex;
          align-items: center;
          background: #ffffff;
          padding: 16px;
          .van-button {
            width: 120px;
            height: 60px;
            margin-right: 16px;
            font-size: 26px;
          }
        }
        .sontable_box {
          overflow-x: auto;
          background: #ffffff;
          // flex: 1;
          .table {
            width: 100%;
            border-spacing: 0px;
            tr {
              td:nth-child(1) {
                border-left: 2px solid #dcdfe6;
              }
              th,
              td {
                padding: 6px 10px;
                text-align: center;
                border-bottom: 2px solid #dcdfe6;
                border-right: 2px solid #dcdfe6;
                .comm_box {
                  display: flex;
                  justify-content: center;
                  .boolean_box {
                    .van-checkbox {
                      justify-content: center;
                      /deep/.van-checkbox__icon--checked {
                        .van-icon {
                          color: #fff !important;
                          background-color: #1989fa !important;
                          border-color: #1989fa !important;
                        }
                      }
                      /deep/.van-checkbox__icon--disabled .van-icon {
                        background-color: #fff;
                      }
                    }
                  }
                  .input_box {
                    width: 150px;
                    .van-cell {
                      font-size: 24px;
                      border: 2px solid #d2d2d2;
                      border-radius: 10px;
                      padding: 8px 16px;
                    }
                  }
                }
              }
            }
            .top {
              position: sticky;
              top: 0;
              font-size: 28px;
              background: #e9f3fd;
              height: 64px;
              th:nth-child(1) {
                border-left: 2px solid #dcdfe6;
              }
              th {
                border-top: 2px solid #dcdfe6;
              }
            }
            .cont {
              height: 64px;
              font-size: 24px;
            }
            .cont:nth-child(2n-1) {
              background: #f5f5f5;
            }
            .nodata {
              td {
                font-size: 28px;
              }
            }
          }
        }
      }
    }
  }
  .footerBox {
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    .flexBox {
      width: 100vw;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 80%;
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>